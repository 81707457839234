import "../styles/footerstyles.css";
import fb from '../img/homeimg/fblogo.png';
import insta from '../img/homeimg/instalogo.png';
import Form from '../pages/Form';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
function footer() {


    // const [currentPage, setCurrentPage] = useState('Home')
    //   const navigateTo = (page) => {
    //     setCurrentPage(page);
    //   }
    //const navigate = useNavigate();
    // function showForm () {
    //     return <Form />
    // }

    return (
        <footer>
            <a href="./Contact" className="mailfooter"> grupo@asesores.com.sv</a>
            <br />
            <div className="socialmediadiv">
                <a href="https://www.facebook.com/grupoasesores.sv" className="media_links" target="_blank">
                    <img src={fb} alt="insta image" width={35} height={35}></img>
                </a>
                <a href="https://www.instagram.com/grupoasesores.sv/" className="media_links" target="_blank">
                    <img src={insta} alt="insta image" width={35} height={35}></img>
                </a>
            </div>


            <p>&copy; 2024 Grupo Asesores. Todos los derechos reservados.</p>

        </footer>
    );
}
export default footer;
