import "../styles/homestyles.css";
import { motion } from 'framer-motion';
import asgif from '../img/homeimg/gifas.gif';
function Home() {

    const getWidth = () => {
        const screenWidth = window.innerWidth;
        // Adjust the logic based on your requirements
        // Here's just a simple example
        if (screenWidth >= 1200) {
            return '70%'; // Full width for large screens
        } else if (screenWidth >= 768) {
            return '70%'; // 80% width for medium screens
        } else {
            return '110%'; // 50% width for small screens
        }
    };

    return (
        
        <section class="herohome">
            
            <div className="blackdiv">
                {/* <div className="blurEffect1">

                </div> */}
                <img
                    src={asgif}
                    autoPlay
                    loop
                    muted
                   style={{width: getWidth(), height: 'auto'}}
                />
            </div>
            <div className="blackdiv2">
                <motion.div className="divtext" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ x: window.innerHeight }}
                    transition={{ ease: "easeIn", duration: 2.2 }}>
                    <motion.p className='heroText' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ x: window.innerHeight }}
                        transition={{ ease: "easeIn", duration: 2.4 }}>Producimos soluciones y experiencias,
                        generando éxitos en marcas y productos dentro del rubro publicitario. Pensamos  y ejecutamos todo en un sólo lugar.
                        Somos la única agencia con <b className="homeboldtext">#AllinOne</b>, posicionada
                        regionalmente por más de <b className="homeboldtext">22 años </b>
                        colaborando con nuestros socios estratégicos gracias a nuestro equipo creativo y planta de producción.
                    </motion.p>
                </motion.div>
            </div>
        </section>
    );
}

export default Home;