import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Portafolio from '../pages/Portafolio';
import Contact from '../pages/Contact';
import Form from '../pages/Form';
import ItemDetails from '../components/ItemDetails';
import MenuNavBar from '../components/navbar';
import InfoSent from '../pages/InfoSent';
function FormRouteNav() {


  return (

    <Router>

      <Routes>
        <Route path="/Form" exact Component={Form} />
        <Route path="/InfoSent" exact Component={InfoSent} />
      </Routes>
    </Router>
  );
}
export default FormRouteNav;