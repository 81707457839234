import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Portafolio from '../pages/Portafolio';
import Contact from '../pages/Contact';
import Form from '../pages/Form';
import ItemDetails from '../components/ItemDetails';
import { AnimatePresence } from "framer-motion";
import MenuNavBar from '../components/navbar';
function navigationComponent({ showNavbar = true }) {


  return (

    <Router>
      <AnimatePresence>
        <Routes>
          <Route>
            {/* <MenuNavBar/> */}
            {/* {showNavbar && <MenuNavBar />} */}
            <Route path="/" exact Component={Home} />
            <Route path="/About" exact Component={About} />
            <Route path="/Portafolio" exact Component={Portafolio} />
            <Route path="/Contact" exact Component={Contact} />
            <Route path="/Form" exact Component={Form} />
            <Route path="/Portafolio/item/:id" exact Component={ItemDetails} />
          </Route>
        </Routes>
      </AnimatePresence>
    </Router>
  );
}
export default navigationComponent;