import logo from './logo.svg';
import './styles/App.css';
import MenuNavBar from './components/navbar';
import Footer from './components/footer';
import NavigationComponent from './components/navigationComponent';
import FormRouteNav from './components/FormRouteNav';
import insta from './img/homeimg/instalogo.png';
import { MyProvider } from './components/ItemContext';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Form from './pages/Form';
import About from './pages/About';
import InfoSent from './pages/InfoSent';


function App() {

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

if(window.location.pathname === "/Form") {
  return(
    <FormRouteNav/>
  );
}


  return (
    <body className="App">

      <header className="App-header">
        <MenuNavBar/>
      </header>
      <div className={`section ${isVisible ? 'visible' : 'hidden'}`}>
        <MyProvider>
          
            <NavigationComponent/>
        </MyProvider>
        {/* <NavigationComponent showNavbar={false}/> */}
        <Footer />
      </div>

    </body>

  );
}


export default App;



  /* let component 
  switch (window.location.pathname) {
      case "/":
        component = <Home/>
        break
        case"/About":
        component = <About/>
        break
        case"/Portafolio":
        component = <Portafolio/>
        break
        case"/Contact":
        component = <Contact/>
        break
  } */
   
  // if (location.pathname === "/") {
  //   return (
  //     <FormRouteNav></FormRouteNav>
  //   );
  // }