import { useContext, useState } from "react";
import { useParams } from "react-router-dom"
import MyContext from './ItemContext';
import '../styles/itemdetails.css';
import { motion } from "framer-motion";

const ItemDetails = ({ isOpen, onClose }) => {


    const { selectedItem } = useContext(MyContext);
    const [showModal, setShowModal] = useState(false);

    // const handleCloseModal = () => {
    //     setShowModal(false);
    //     console.log('eto NO furula');
    // }
    console.log(selectedItem.description)

    const getWidth = () => {
        const screenWidth = window.innerWidth;
        // Adjust the logic based on your requirements
        // Here's just a simple example
        if (screenWidth >= 1200) {
            return '65%'; // Full width for large screens
        } else if (screenWidth >= 768) {
            return '70%'; // 80% width for medium screens
        } else {
            return '110%'; // 50% width for small screens
        }
    };
    if (!isOpen) return null;
    /* const params = useParams();
    const [item, setItem] = useState(null);
    console.log(index);
 */
    return (


        // <div className="itemstyle">

        //     {selectedItem &&
        //         <div className="modalStyles">
        //             <a className="closeitem" onClick={onClose} >
        //                 x
        //             </a>


        //             {items.map((item) => (
        //                 <div key={item.id} onClick={() => handleClick(item)} className="divtry">

        //                     <img
        //                         // key={index}
        //                         src={item.images[2]}
        //                         alt="img1dolofin"
        //                         width="630px"
        //                         height="400px"
        //                         className="carouselImage"
        //                     />
        //                 </div>
        //             ))}
        //         </div>
        //     }


        // --------------------------- PREVIOUS MODAL SCREEN THATS NOT A PROPER MODAL

        <motion.div className="firstdiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ x: window.innerHeight }}
            transition={{ ease: "easeIn", duration: 1.7 }}>
            <a href="/Portafolio" className="closeitem" onClick={onClose} >
                x
            </a>
            {selectedItem?.type === 'image' && (
                <img src={selectedItem.images[1]} alt="imgsrc"
                    style={{  // Default width to ensure responsiveness
                        width: getWidth(),
                        height: 'auto',// Centers the image horizontally
                    }}
                    className="contentdiv" />
            )
            }
            {
                selectedItem?.type === 'video' && (
                    <video src={selectedItem.src} alt="imgsrc"
                        autoPlay
                        controls
                        loop
                        muted
                        style={{ width: '70vw', height: '70vh' }}
                        className="videodiv" />
                )
            }


        </motion.div>
        //     --------------------------------------------- ENDS HERE
        //






        // </div>
    );
}

export default ItemDetails;