import '../styles/formstyle.css'
import React, { useState, useRef, useEffect } from "react";
import emailjs from '@emailjs/browser';
import { motion } from 'framer-motion';
import LogoAs from '../img/homeimg/logo.png';
import AllInOne from '../img/homeimg/allinoneform.png';
import { ToastContainer, toast } from 'react-toastify';
import InfoSent from './InfoSent'
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

//import { isVisible } from '@testing-library/user-event/dist/utils';
function Form() {
    const info = useRef();
    const notify = () => toast("¡Información enviada!");
    const navigate = useNavigate();
    const showInfoSent = () => {
        if (window.location.pathname === "/InfoSent") {
          return <InfoSent />;
        }
      };

    const [formInfo, setFormInfo] = useState({
        name: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        company: '',
        role: '',
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        lastname: '',
        email: '',
        phoneNumber: '',
        company: '',
        role: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInfo(prevState => ({
            ...prevState,
            [name]: value

        }));
    };

    const namePattern = /^[a-z A-Z ]{3,}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberPattern = /^\d{8,}$/;



    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = {};


        if (!formInfo.name.trim()) {
            errors.name = 'Este campo es obligatorio';
        } else if (!namePattern.test(formInfo.name)) {
            errors.name = 'El nombre debe contener solo letras y por lo menos 3 caracteres.';
        }
        if (!formInfo.lastname.trim()) {
            errors.lastname = 'Este campo es obligatorio';
        } else if (!namePattern.test(formInfo.lastname)) {
            errors.lastname = 'Formato de apellido inválido';
        }
        if (!formInfo.email.trim()) {
            errors.email = 'Email vacío, por favor rellene este campo.';
        } else if (!emailPattern.test(formInfo.email)) {
            errors.email = 'Formato de email inválido';
        }
        if (!formInfo.phoneNumber.trim()) {
            errors.phoneNumber = 'Número de teléfono vacío, por favor rellene este campo.';
        } else if (!phoneNumberPattern.test(formInfo.phoneNumber)) {
            errors.phoneNumber = 'Formato de número inválido';
        }
        if (!formInfo.company.trim()) {
            errors.company = 'Campo vacío, por favor rellene este campo.';
        }
        if (!formInfo.role.trim()) {
            errors.role = 'Campo vacío, por favor rellene este campo.';
        }
        setFormErrors(errors);

        //sending the form if 
        if (Object.keys(errors).length === 0) {
            emailjs
                .sendForm('service_lkco6o7', 'template_6lizkuf', info.current, {
                    publicKey: 'jzVHJ8JChg0wsd_VK',
                })
                .then(
                    () => {

                        navigate('/InfoSent');
                        console.log('SUCCESS!');
                        console.log('message send!');
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    },
                );
        }

        console.log(formInfo);
        // Reset the form after submission
        setFormInfo({
            name: '',
            lastname: '',
            email: '',
            phoneNumber: '',
            company: '',
            role: '',
        });
    };


    return (
        //<body   className={`section ${isVisible ? 'visible' : 'hidden'}`}>
        <section className="form-containerf">

            <ToastContainer />
            <motion.form className="form-stylef" onSubmit={handleSubmit} ref={info} style={{ marginBottom: formErrors.email && '70px' }}>
                <img src={LogoAs} alt="logoimg" style={{ height: "45%", width: "35%" }} />
                <img src={AllInOne} alt="logoimg" style={{ height: "80%", width: "55%" }} />
                <h2 className="formTitlef">¡DESCUBRE NUESTRAS EXPERIENCIAS!</h2>
                <div className="input-div-stylesf">
                    <div>
                        <label htmlFor="name">Nombre:</label>
                    </div>
                    <div className="div-input-areaf">
                        <motion.input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Inserta aquí tu nombre"
                            className="inputClassf"
                            value={formInfo.name}
                            onChange={handleChange}

                            style={{ borderColor: formErrors.name && 'red' }}
                            //onBlur={handleBlur}
                            //required
                            initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth }}
                            transition={{ ease: "easeOut", duration: 0.4 }}
                        />
                        {formErrors.name && <p>{formErrors.name}</p>}
                    </div>
                </div>
                <div className="input-div-stylesf">
                    <div>
                        <label htmlFor="lastname">Apellido:</label>
                    </div>
                    <div className="div-input-areaf">
                        <motion.input
                            type="text"
                            id="lastname"
                            name="lastname"
                            placeholder="Inserta aquí tu apellido"
                            className="inputClassf"
                            value={formInfo.lastname}
                            onChange={handleChange}

                            style={{ borderColor: formErrors.lastname && 'red' }}
                            //onBlur={handleBlur}
                            //required
                            initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth }}
                            transition={{ ease: "easeOut", duration: 0.4 }}
                        />
                        {formErrors.lastname && <p>{formErrors.lastname}</p>}
                    </div>
                </div>
                <div className="input-div-stylesf">
                    <div>
                        <label htmlFor="email">Email:</label>

                    </div>
                    <div className="div-input-areaf">
                        <motion.input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Inserta aquí tu correo"
                            value={formInfo.email}
                            onChange={handleChange}
                            //required
                            style={{ borderColor: formErrors.email && 'red' }}
                            initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth }}
                            transition={{ ease: "easeOut", duration: 0.4 }}
                        />
                        {formErrors.email && <p>{formErrors.email}</p>}
                    </div>
                </div>
                <div className="input-div-stylesf">
                    <div>
                        <label htmlFor="phoneNumber">Número de teléfono:</label>

                    </div>
                    <div className="div-input-areaf">
                        <motion.input
                            type="phoneNumber"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Inserta aquí tu número de teléfono"
                            value={formInfo.phoneNumber}
                            onChange={handleChange}
                            //required
                            style={{ borderColor: formErrors.email && 'red' }}
                            initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth }}
                            transition={{ ease: "easeOut", duration: 0.4 }}
                        />
                        {formErrors.phoneNumber && <p>{formErrors.phoneNumber}</p>}
                    </div>

                </div>

                <div className="input-div-stylesf">
                    <div>
                        <label htmlFor="company">Empresa:</label>
                    </div>
                    <div className="div-input-areaf">
                        <motion.input
                            type="text"
                            id="company"
                            name="company"
                            placeholder="Inserta aquí la empresa donde trabajas"
                            className="inputClassf"
                            value={formInfo.company}
                            onChange={handleChange}

                            style={{ borderColor: formErrors.company && 'red' }}
                            //onBlur={handleBlur}
                            //required
                            initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth }}
                            transition={{ ease: "easeOut", duration: 0.4 }}
                        />
                        {formErrors.company && <p>{formErrors.company}</p>}
                    </div>
                </div>

                <div className="input-div-stylesf">
                    <div>
                        <label htmlFor="role">Cargo:</label>
                    </div>
                    <div className="div-input-areaf">
                        <motion.input
                            type="text"
                            id="role"
                            name="role"
                            placeholder="Inserta aquí el cargo que desempeñas"
                            className="inputClassf"
                            value={formInfo.role}
                            onChange={handleChange}

                            style={{ borderColor: formErrors.role && 'red' }}
                            //onBlur={handleBlur}
                            //required
                            initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth }}
                            transition={{ ease: "easeOut", duration: 0.4 }}
                        />
                        {formErrors.role && <p>{formErrors.role}</p>}
                    </div>
                </div>
                <div className="btn-form-stylef">
                    <motion.button type="submit" initial={{ width: 0 }} animate={{ width: "100%" }} exit={{ x: window.innerWidth }}
                        transition={{ ease: "easeOut", duration: 0.4 }}>Enviar</motion.button>
                    
                </div>
               
            </motion.form>
            {/* <button onClick={showInfoSent}>ave</button> */}
            {/* <button onClick={notify} className="btn-modal">
                        toast
                    </button> */}
        </section>
        

    );
}

export default Form;

