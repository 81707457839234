import React, { useState, useContext, useEffect } from "react";
import Carousel from "react-multi-carousel";
import '../styles/tworowcarousel.css';
// import metaDay from '../img/imgcarousel/Estructura.jpg';
// import eventoTp from '../img/imgcarousel/Eventos.jpg';
// import impresionUv from '../img/imgcarousel/UV.jpg';
// import binaes from '../img/imgcarousel/binaes.mp4';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MyContext from './ItemContext';
import ItemDetails from "./ItemDetails";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { settings } from "firebase/analytics";

const TwoRowCarousel = () => {

  const [showModal, setShowModal] = useState(false);
  const { handleSelectedItem } = useContext(MyContext);

  const items =
    [
      {
        id: 1, title: "Evento Teleperformance", description: "¡Diseño, producción, montaje y experiencias para celebración de comienzo del año 2024 con Teleperformance! Para fiesta de inicio de año llena de emoción y alegría.", type: 'image',
        images: [
          // metaDay,
          // impresionUv,
          // eventoTp
        ]
      }
      // { id: 5, title: "Evento Tigool", description: "Video resumen de evento Tigoool.", type: 'video', src: videotigo },
      // { id: 6, title: "Plotter", description: "Producción e instalación de campaña publicitaria en todas las agencias Banco Azul del país.", type: 'image', src: plotterBancoAzul },
      // {
      //   id: 3, title: "BINAES", description: "¡Exhibición Star Wars! Diseño y producción de área temática BINAES. Nos encantan los retos, y este ha sido uno donde nuestra creatividad y capacidad productiva nos permitió involucrarnos desde la idea a la ejecución de ambientes, muebles, rótulos, iluminación y experiencias Star Wars.",
      //   type: 'video',
      //   src: binaes
      // },

      // {
      //   id: 8, title: "Meta Day El Salvador ", description: "Estructura: Meta Day El Salvador nos permitió explotar nuestra creatividad y producción colaborando en conjunto con nuestro partner @Meta sobre sus estrategias digitales para el sector universiatrio, pequeña empresa y Ministerio de Economía en El Salvador",
      //   type: 'image',
      //   src: metaDay
      // },

      // { id: 10, title: "Activación BTL", description: "¡Déjate sorprender por tu marca! Activación BTL Panda Express. Nos encanta ser parte de tus estrategias comerciales.", type: 'image', src: activacionPanda },
      // { id: 11, title: "Termoformado: Pilsener", description: "Porta afiches termoformados, con realces en detalles con impresión directa en lámina de estireno.", type: 'image', src: termoformadoPilsener },
      // { id: 12, title: "Corte Láser", description: "¡Reconocimiento empresarial! Nos fascina operar en las estrategias de tu marca con un gran impacto.", type: 'image', src: coprteLaser },
      // { id: 13, title: "Impresión UV", description: "Rótulos impresos sobre ACM con efecto realzado UV Emboss Clear para generar el sistema de lectoescritura táctil. ", type: 'image', src: impresionUv },
    ]
    ;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 721 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };
  const responsiveSettings  = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]

  const handleClick = (item) => {
    handleSelectedItem(item);
    setShowModal(!showModal);
    console.log('eto furula');
  }


  const handleCloseModal = () => {
    setShowModal(false);
    console.log('eto NO furula');
  }

  return (




    // *********** CAROUSEL ACAAAAAAAAA *******
    < div >
      <Carousel
        responsive={responsive}
        infinite={true}
        centerMode={true}
      >


        {/* {items.map((item, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }} > */}

        {/* {item?.type === 'image' && (
              <Link to={'./item/${item.id}'}> */}
        {items.map((item) => (
          <div key={item.id} onClick={() => handleClick(item)} className="divtry">

            <img
              // key={index}
              src={item.images[0]}
              alt="img1dolofin"
              width="630px"
              height="400px"
              className="carouselImage"
            />
            <img
              // key={index}
              src={item.images[0]}
              alt="img1dolofin"
              width="630px"
              height="400px"
              className="carouselImage"
            />
            <img
              // key={index}
              src={item.images[0]}
              alt="img1dolofin"
              width="630px"
              height="400px"
              className="carouselImage"
            />
            <img
              // key={index}
              src={item.images[0]}
              alt="img1dolofin"
              width="630px"
              height="400px"
              className="carouselImage"
            />
            <img
              // key={index}
              src={item.images[0]}
              alt="img1dolofin"
              width="630px"
              height="400px"
              className="carouselImage"
            />
          </div>
        ))}

      </Carousel>

      
    

    </div >

  )
};

export default TwoRowCarousel;