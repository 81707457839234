import React from "react";
import '../styles/infosent.css';

import LogoAs from '../img/homeimg/logo.png';
export default function InfoSent() {
  return (
    <>
      <body>
        <div className="divinfo">
          <img src={LogoAs} alt="logoimg" style={{ height: "250px", width: "270px" }} />
          <h1>¡Información Enviada!</h1>
        </div>


      </body>



    </>
  );
}