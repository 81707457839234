import '../styles/portafoliostyles.css';
import produccion from '../img/portafolioimg/PRODUCCION.png';
import TwoRowCarousel from '../components/carousel';
import "react-multi-carousel/lib/styles.css";
import { useState, useEffect, useContext } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import MyContext from '../components/ItemContext';
import ItemDetails from '../components/ItemDetails';
import MenuNavBar from '../components/navbar';
import Carousel from "react-multi-carousel";
//proyecto 1 
import portada1 from '../img/portafolioimg/carousel/PROYECTO 1/PORTADA-1.png';
import img11 from '../img/portafolioimg/carousel/PROYECTO 1/1-FOTO-1.jpg';
import img12 from '../img/portafolioimg/carousel/PROYECTO 1/1-FOTO-2.jpg';
import img13 from '../img/portafolioimg/carousel/PROYECTO 1/1-FOTO-3.jpg';
import img14 from '../img/portafolioimg/carousel/PROYECTO 1/1-FOTO-4.jpg';
import img15 from '../img/portafolioimg/carousel/PROYECTO 1/1-FOTO-5.jpg';
//proyecto 2 
import portada2 from '../img/portafolioimg/carousel/PROYECTO 2/PORTADA-2.png';
import img21 from '../img/portafolioimg/carousel/PROYECTO 2/2-FOTO-1.jpg';
import img22 from '../img/portafolioimg/carousel/PROYECTO 2/2-FOTO-2.jpg';
import img23 from '../img/portafolioimg/carousel/PROYECTO 2/2-FOTO-3.jpg';
import img24 from '../img/portafolioimg/carousel/PROYECTO 2/2-FOTO-4.jpg';
//proyecto 3 
import portada3 from '../img/portafolioimg/carousel/PROYECTO 3/PORTADA-3.png';
import img31 from '../img/portafolioimg/carousel/PROYECTO 3/3-FOTO-1.jpg';
import img32 from '../img/portafolioimg/carousel/PROYECTO 3/3-FOTO-2.jpg';
import img33 from '../img/portafolioimg/carousel/PROYECTO 3/3-FOTO-3.jpg';
import img34 from '../img/portafolioimg/carousel/PROYECTO 3/3-FOTO-4.jpg';
import img35 from '../img/portafolioimg/carousel/PROYECTO 3/3-FOTO-5.jpg';
//proyecto 4 
import portada4 from '../img/portafolioimg/carousel/PROYECTO 4/PORTADA-4.png';
import img41 from '../img/portafolioimg/carousel/PROYECTO 4/4--FOTO-1.jpg';
import img42 from '../img/portafolioimg/carousel/PROYECTO 4/4-FOTO-2.jpg';
import img43 from '../img/portafolioimg/carousel/PROYECTO 4/4-FOTO-3.jpg';
import img45 from '../img/portafolioimg/carousel/PROYECTO 4/5--FOTO-5.jpg';
//proyecto 5 
import portada5 from '../img/portafolioimg/carousel/PROYECTO 5/PORTADA-5.png';
import img51 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-1.jpg';
import img52 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-2.jpg';
import img53 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-3.jpg';
import img54 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-4.jpg';
import img55 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-5.jpg';
import img56 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-6.jpg';
import img57 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-7.jpg';
import img58 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-8.jpg';
import img59 from '../img/portafolioimg/carousel/PROYECTO 5/5-FOTO-9.jpg';
//proyecto 6 
import portada6 from '../img/portafolioimg/carousel/PROYECTO 6/PORTADA-6.png';
import img61 from '../img/portafolioimg/carousel/PROYECTO 6/6-FOTO-1.jpg';
import img62 from '../img/portafolioimg/carousel/PROYECTO 6/6-FOTO-2.jpg';
import img63 from '../img/portafolioimg/carousel/PROYECTO 6/6-FOTO-3.jpg';
import img64 from '../img/portafolioimg/carousel/PROYECTO 6/6-FOTO-4.jpg';
import img65 from '../img/portafolioimg/carousel/PROYECTO 6/6-FOTO-5.jpg';
//proyecto 7 
import video7 from '../img/portafolioimg/carousel/PROYECTO 7/KAROL G.mp4'
//proyecto 8
import video8 from '../img/portafolioimg/carousel/PROYECTO 8/CORONA SUNSET.mp4';
//proyecto 9
import video9 from '../img/portafolioimg/carousel/PROYECTO 9/abanicos.mp4';
//proyecto 10
import portada10 from '../img/portafolioimg/carousel/PROYECTO 10/PORTADA-10.png';
import img101 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-1.jpg';
import img102 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-2.jpg';
import img103 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-3.jpg';
import img104 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-4.jpg';
import img105 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-5.jpg';
import img106 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-6.jpg';
import img107 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-7.jpg';
import img108 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-8.jpg';
import img109 from '../img/portafolioimg/carousel/PROYECTO 10/10-FOTO-9.jpg';
//proyecto 11
import video11 from '../img/portafolioimg/carousel/PROYECTO 11/pilsenerOF2023v1.mp4';
//proyecto 12
import video12 from '../img/portafolioimg/carousel/PROYECTO 12/metro.mp4';
import { Zoom } from 'react-slideshow-image';

function Portafolio() {


  const { handleSelectedItem } = useContext(MyContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClick = (item) => {
    handleSelectedItem(item);
    setShowModal(!showModal);
    console.log('eto furula');
  }
  // const handleCloseModal = () => {
  //   setShowModal(false);
  //   console.log('eto NO furula');
  // }

  const openModal = () => {
    setIsModalOpen(true);
  }
  const handleCloseModal = () => {
    setShowModal(false);
    console.log('eto NO furula');
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1300 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 721 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 720, min: 0 },
      items: 1,
    },
  };
  const responsiveSettings = [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
  const items =
    [
      {
        id: 1, title: "YO GARANTIZO LA CALIDAD",
        description: "Diseño de estrategia y campaña de comunicación interna para nuestro socio Grupo Steiner. Contamos con la experiencia perfecta para desarrollar estrategias y campañas publicitarias desde cero, tomando en cuenta tus objetivos corporativos o comerciales.",
        type: 'image',
        images: [
          portada1,
          img11,
          img12,
          img13,
          img14,
          img15
        ]
      },
      {
        id: 2, title: "KITS DE LANZAMIENTO LOTO",
        description: "Producción de empaques personalizados para invitaciones de lanzamiento LOTO en El Salvador. ¡Desafiamos los límites creativos! Hacemos que los detalles sellen tus éxitos.",
        type: 'image',
        images: [
          portada2,
          img21,
          img22,
          img23,
          img24
        ]
      },
      {
        id: 3, title: "CORONA SPIRITUAL HOME",
        description: "Diseño de ambiente personalizado para espacios sponsors de Cerveza Corona. Nos encanta crear espacios disruptivos, donde la esencia de la marca habla por si sola. Diseñamos y producimos todo en un mismo lugar.",
        type: 'image',
        images: [
          portada3,
          img31,
          img32,
          img33,
          img34,
          img35
        ]
      },
      {
        id: 4, title: "PUNTO DE VENTA TERMOFORMADOS",
        description: "Porta afiches y cenefas termoformadas para espacios publicitarios de punto de venta. Somos especialistas en retail, y nuestra fortaleza es saber posicionar tu marca con éxito, para poder destacar cada pieza y mensaje en territorios del día a día.",
        type: 'image',
        images: [
          portada4,
          img41,
          img42,
          img43,
          img45
        ]
      }
      ,
      {
        id: 5, title: "META DAY EL SALVADOR",
        description: "Diseño, producción y montaje de evento META DAY en El Salvador. Una experiencia que engloba dinámicas, estaciones WOW, photobooths y escenografía para ponencias con representantes de META para micro y pequeñas empresas que representan gran peso en la economía de nuestro país.",
        type: 'image',
        images: [
          portada5,
          img51,
          img52,
          img53,
          img54,
          img55,
          img56,
          img57,
          img58,
          img59
        ]
      }
      ,
      {
        id: 6, title: "GIGANTOGRAFÍAS FACHADAS BANCO AZUL",
        description: "¡Producimos campañas publicitarias a lo grande! Nuestra capacidad instalada y acreditaciones para instalaciones en alturas, respaldada por nuestra seguridad industrial, garantiza que tu marca se coloque de la forma correcta. Impresión en lona mesh para fachadas de agencias Banco Azul. ",
        type: 'image',
        images: [
          portada6,
          img61,
          img62,
          img63,
          img64,
          img65
        ]
      }
      ,
      {
        id: 7, title: "EXPERIENCIAS KAROL G – EL SALVADOR",
        description: "Una experiencia llena de color al ritmo de Karol G. Estaciones de fotos y branding impreso para el concierto más esperado del año en El Salvador. Sabemos cómo destacar la esencia de un estilo y tendencia.",
        type: 'video',
        images: [
          video7
        ]
      }
      ,
      {
        id: 8, title: "CORONA SUNSET SESSION 2024",
        description: "Evento de inauguración del verano a cargo de Experiencias CORONA. Nos encargamos de diseñar y montar los espacios estratégicos y llevar a cabo los shows de cada talento para realizar un momento inmersivo en el atardecer al estilo Corona.",
        type: 'video',
        images: [
          video8
        ]
      }
      ,
      {
        id: 9, title: "PRODUCCIÓN OFFSET  - ABANICOS",
        description: "Nuestra planta de producción nos permite entregar en tiempos récord productos impresos en gran escala. Abanicos troquelados para eventos, impresos en papel foldcote laminado.",
        type: 'video',
        images: [
          video9
        ]
      }
      ,
      {
        id: 10, title: "BINAES – ÁREAS TEMÁTICAS",
        description: "¡Exhibición Star Wars! Diseño y producción de área temática BINAES. Nos encantan los retos, y este ha sido uno donde nuestra creatividad y capacidad productiva nos permitió involucrarnos desde la idea a la ejecución de ambientes, muebles, rótulos, iluminación y experiencias Star Wars.",
        type: 'image',
        images: [
          portada10,
          img101,
          img102,
          img103,
          img104,
          img105,
          img106,
          img107,
          img108,
          img109
        ]
      }
      ,
      {
        id: 11, title: "OCTOBERFEST PILSENER ",
        description: "Nos fascina recrear ambientes con el estilo y concepto de tu marca. Spots Pilsener y Photobooths digitales para el evento más cervecero del año.",
        type: 'video',
        images: [
          video11
        ]
      }
      ,
      {
        id: 12, title: "SAN VALENTIN METROCENTRO",
        description: "Diseño y montaje de experiencias para todos los centros comerciales Metrocentros de El Salvador, utilizando la campaña de San Valentin, para generar espacios llenos de momentos alusivos a la temporada.",
        type: 'video',
        images: [
          video12
        ]
      }

    ]
  const { selectedItem } = useContext(MyContext);
  const getWidth = () => {
    const screenWidth = window.innerWidth;
    // Adjust the logic based on your requirements
    // Here's just a simple example
    if (screenWidth >= 1200) {
      return '65%'; // Full width for large screens
    } else if (screenWidth >= 768) {
      return '70%'; // 80% width for medium screens
    } else {
      return '110%'; // 50% width for small screens
    }
  };
  return (
    <body className='bodyporta'>
      {showModal &&
        <div className='modalStyles'>
          <a className="closeitem" onClick={handleCloseModal} >
            x
          </a>


          <div className='modalDivStyles'>
            {selectedItem?.type === 'video' ? (
              <Zoom autoplay={false} infinite={false}>
             { selectedItem?.images.map((images, index) => (
                  <video
                    key={index}
                    src={images}
                    autoPlay
                    loop
                    muted
                    controls
                    className="videocarrosel"
                  />
                
              ))}
              </Zoom>
            ) :
              (<Zoom indicators>
                {selectedItem?.images.map((image, index) => (
                  
                    <img src={image} key={index} style={{
                      display: 'block',
                      width: "100%",
                      height: '65vh',
                      maxHeight: '100%',
                      padding: '40px',
                      alignSelf: 'center',
                      justifySelf: 'center'
                    }}
                    className='carouselImage' 
                    />

                  
                ))}
                </Zoom>
              )}
            {/* <Zoom scale={1.4} indicators={true} infinite={false}>
              {selectedItem?.type === 'video' ? (
                <video
                  // key={index}
                  src={selectedItem.images[0]}
                  autoPlay
                  loop
                  muted
                  className="videocarrosel"
                />
              ) : (
                <img src={selectedItem.images[0]} style={{
                  display: 'block',
                  width: "55vw",
                  height: '80vh',
                  maxHeight: '100%',
                  padding: '40px',
                  alignSelf: 'center',
                  justifySelf: 'center'
                }} />

              )

              }









            </Zoom> */}
          </div>

          {
            selectedItem && (
              <div className="divitem">
                <h2>{selectedItem ? selectedItem.title : 'no item selected'} </h2>
                <p>{selectedItem ? selectedItem.description : 'no item selected'} </p>
              </div>
            )
          }
        </div>
      }
      {/* <section class={`grid-container ${isVisible ? 'visible' : 'hidden'} `}> */}
      <section className='grid-container'>

        <div className='griditems'>
          <div class="grid-item1">
          </div>
          <div className='colum-div-1'>
            <h1 className='portfolio-h1'>SOMOS EXPERTOS</h1>
            <p className='portfolio-text'>Impresión Offset, Impresión Digital, Impresión UV, Serigrafía, Sublimación, Termoformado Plotter, Corte Láser, Madera, Acrílico, Estructuras, Promocionales.</p>
          </div>

        </div>
        <div className='griditems'>

          <div class="grid-item2">
          </div>
          <div className='colum-div-2'>
            <h1 className='portfolio-h1'>CREAMOS EXPERIENCIAS</h1>
            <p className='portfolio-text'>Activaciones de Marca, Lanzamientos, Perifoneo, Shows, BTL, Sampling, PhotoBooth, VideoBooth, Audio y Sonido, Cobertura, Pantallas, Eventos.</p>
          </div>
        </div>

        <div className='griditems'>
          <div class="grid-item3">
          </div>
          <div className='colum-div-3'>
            <h1 className='portfolio-h1'>SOMOS CREATIVOS</h1>
            <p className='portfolio-text'>Estrategias, Branding, Social Media, Trademarketing, Diseño Editorial, Diseño Digital,  Diseño Industrial, Diseño Publicitario, Diseño de Apps y Video Juegos. </p>
          </div>
        </div>


      </section>
      <div className='carousel'>
        {/* <TwoRowCarousel className='complete-carousel' /> */}
        <Carousel
          responsive={responsive}
          infinite={true}
          centerMode={true}
          arrows={false}
          showDots={true}
        >
          {items.map((item) => (
            <div key={item.id} onClick={() => handleClick(item)} className="divtry">
              {item?.type === 'image' && (
                <img
                  // key={index}
                  key={item.id}
                  src={item.images[0]}
                  alt="img1dolofin"
                  
                  className="carouselImage"
                />
              )
              }
              {
                item?.type === 'video' && (
                  <video
                    // key={index}
                    key={item.id}
                    src={item.images[0]}
                    autoPlay
                    loop
                    muted
                    className="videocarrosel"
                  />
                )
              }



            </div>
          ))}


        </Carousel>
      </div>
      {/* <Slider {...settings}>
        

        {items.map((item, index) => (
          <div key={index} className='sliderdiv' >

            {item?.type === 'image' && (
              <Link to={'./item/${item.id}'} className='divlink'>
                <div onClick={() => handleClick(item)} className="divtry" >
                  <img
                    key={index}
                    src={item.src}
                    alt="img1dolofin"
                    className="carouselImage"
                  />
                </div>

              </Link>

            )}
            {item?.type === 'video' && (
              <Link to={'./item/${item.id}'}>
                <div onClick={() => handleClick(item)} className="divtry">
                  <video
                    src={item.src}
                    autoPlay
                    loop
                    muted
                    className="videocarrosel"
                  />
                </div>
              </Link>
            )}

          </div>
        ))}
        
      </Slider> */}

    </body >
  );
}

export default Portafolio;
